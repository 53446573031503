var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Hi1r3TF7E2xcG46G_Y5Ha"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const siteConfig = require(`../../../applications/${process.env.NEXT_PUBLIC_SITE}/env`);

const SENTRY_DSN = siteConfig.private.sentryDSN;

SENTRY_DSN && import("./sentry.client.init.config.js");
